import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginPathWithRedirect } from 'utils/redirect'

const DefaultRouteRedirection = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (window.user_id_at_spa_load) {
      navigate(window.redirect_path)
    } else {
      navigate(loginPathWithRedirect())
    }
  }, [])

  return null
}

export default DefaultRouteRedirection
