import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import i18next from 'i18next'
import httpClient, { HttpClientError, csrfToken } from 'utils/http-client'
import SkribeLogoShort from 'components/SkribeLogoShort'
import Input from 'components/Input'
import Text from 'components/Text'
import Button from 'components/Button'
import PasswordInput from 'components/PasswordInput'
import { CGU_LINK, LEGAL_NOTICE_LINK } from 'employee/utils/legal_links'
import { addUserId } from 'utils/appsignal'
import { useURLRedirectPath } from 'utils/redirect'

type LoginFormErrors = {
  not_found: boolean
  invalid_password: boolean
}

const Login = () => {
  const navigate = useNavigate()
  const [errors, setErrors] = React.useState<LoginFormErrors>({ not_found: false, invalid_password: false })
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const URLredirectPath = useURLRedirectPath()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    httpClient<{ redirect_path: string; id: string }>('/session', {
      method: 'post',
      body: { email, password },
    })
      .then(({ id, redirect_path }) => {
        addUserId(id, 'employee')

        window.redirect_path = redirect_path
        navigate(URLredirectPath ?? redirect_path)
      })
      .catch((error: HttpClientError) => {
        if (error.response?.status === 401) {
          setErrors({ not_found: false, invalid_password: true })
        } else if (error.response?.status === 404) {
          setErrors({ not_found: true, invalid_password: false })
        }
      })
  }

  return (
    <div className="min-h-lvh px-12 pt-24 pb-14 bg-backgroundPrimary flex flex-col justify-between">
      <div>
        <div className="flex flex-col place-items-center gap-2">
          <SkribeLogoShort />
          <Text type="title">{i18next.t('employee.login.title')}</Text>
          <Text type="subtext" className="italic mt-2">
            {i18next.t('employee.login.subtitle')}
          </Text>
        </div>
        <div className="mt-6">
          <form className="mt-8" onSubmit={onSubmit}>
            <input type="hidden" name="authenticity_token" value={csrfToken()} />
            <div className="relative w-full mb-6">
              <Input
                name="email"
                label={i18next.t('common.email')}
                type="email"
                placeholder={i18next.t('common.email_placeholder')}
                value={email}
                onChange={(newValue) => {
                  setEmail(newValue)
                  setErrors((currentErrors) => ({ ...currentErrors, not_found: false }))
                }}
                error={errors.not_found ? i18next.t('employee.login.not_found') : undefined}
                required
              />
              <PasswordInput
                className="mt-4"
                value={password}
                onChange={(newValue) => {
                  setPassword(newValue)
                  setErrors((currentErrors) => ({ ...currentErrors, invalid_password: false }))
                }}
                error={errors.invalid_password ? i18next.t('employee.login.invalid_password') : undefined}
              />
            </div>
            <Button
              type="submit"
              name={i18next.t('common.log_in')}
              disabled={errors.invalid_password || errors.not_found}
              className="w-full"
            >
              {i18next.t('common.log_in')}
            </Button>
          </form>
          <div className="flex justify-center pt-6">
            <Link to={{ pathname: '/employee/password/new', search: email ? `?email=${email}` : '' }}>
              <Text uiStyle="bold" className="text-textAccent">
                {i18next.t('employee.login.forgot_password')}
              </Text>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-6">
        <a href={CGU_LINK} target="_blank" rel="noopener noreferrer">
          <Text type="smalltext">{i18next.t('employee.legal.cgu')}</Text>
        </a>
        <span className="mx-2">•</span>
        <a href={LEGAL_NOTICE_LINK} target="_blank" rel="noopener noreferrer">
          <Text type="smalltext">{i18next.t('employee.legal.notice')}</Text>
        </a>
      </div>
    </div>
  )
}

export default Login
