import React from 'react'
import Modal from 'react-modal'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRoutesFromElements, Route, RouterProvider, createBrowserRouter } from 'react-router-dom'
import i18next from 'i18next'

import PageWrapper from 'components/PageWrapper'
import RouteWatcher from 'components/RouteWatcher'
import { initAppsignal } from 'utils/appsignal'
import { getDefaultQueryClient } from 'utils/react-query'
import { setupCookiesCallback } from 'utils/cookies'
import { initDayjs } from 'utils/dayjs'
import ErrorPage from 'components/ErrorPage'
import Toaster from 'components/Toaster'
import Skeleton from './routing/Skeleton'
import PublicRoutes from './routing/PublicRoutes'
import Signup from './pages/Signup'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import AgencyList from './pages/agencies/AgencyIndex'
import AgencyShow from './pages/agencies/AgencyShow'
import DocumentGroupIndex from './pages/document_groups/DocumentGroupIndex'
import DocumentGroupList from './pages/document_groups/DocumentGroupList'
import DocumentGroupFolder from './pages/document_groups/DocumentGroupFolder'
import DocumentGroupShow from './pages/document_groups/DocumentGroupShow'

import AccountShow from './pages/account/AccountShow'
import AccountPrivacySettings from './pages/account/AccountPrivacySettings'
import AgencyReferralShow from './pages/agencies/AgencyReferralShow'
import PublicAgencyReferralShow from './pages/PublicAgencyReferralShow'
import DefaultRouteRedirection from './pages/DefaultRouteRedirection'

Modal.setAppElement('#root')

const queryClient = getDefaultQueryClient()

initAppsignal('employee')
setupCookiesCallback()

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RouteWatcher />} errorElement={<ErrorPage />}>
      <Route path="/" element={<DefaultRouteRedirection />} />

      <Route
        path="/document_groups/:documentGroupId/:slug/list"
        element={
          <PageWrapper
            paramKeys={['documentGroupId', 'slug']}
            titleKey="employee.document_group.title"
            component={DocumentGroupList}
          />
        }
      />
      <Route
        path="/document_groups/:documentGroupId/:slug/folder"
        element={
          <PageWrapper
            paramKeys={['documentGroupId', 'slug']}
            titleKey="employee.document_group.title"
            component={DocumentGroupFolder}
          />
        }
      />
      <Route
        path="/document_groups/:documentGroupId/:slug/"
        element={
          <PageWrapper
            paramKeys={['documentGroupId', 'slug']}
            titleKey="employee.document_group.title"
            component={DocumentGroupShow}
          />
        }
      />

      <Route element={<Skeleton />}>
        <Route
          path="/document_groups"
          element={<PageWrapper titleKey="employee.document_groups.title" component={DocumentGroupIndex} />}
        />

        <Route
          path="/agencies"
          element={<PageWrapper titleKey="employee.agency_list.accepted_invites" component={AgencyList} />}
        />
        <Route
          path="/agencies/:agencyId"
          element={<PageWrapper paramKeys={['agencyId']} titleKey="employee.agency.title" component={AgencyShow} />}
        />
        <Route
          path="/agencies/referral"
          element={<PageWrapper titleKey="employee.referral.title" component={AgencyReferralShow} />}
        />

        <Route path="/account" element={<PageWrapper titleKey="employee.account.title" component={AccountShow} />} />
        <Route
          path="/account/privacy_settings"
          element={<PageWrapper titleKey="employee.account.privacy.title" component={AccountPrivacySettings} />}
        />
      </Route>

      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<PageWrapper titleKey="employee.login.title" component={Login} />} />
        <Route
          path="/employee/password/new"
          element={<PageWrapper titleKey="employee.forgot_password.title" component={ForgotPassword} />}
        />
        <Route
          path="/employee/password/edit"
          element={<PageWrapper titleKey="common.reset_password.title" component={ResetPassword} />}
        />
        <Route
          path="/signup/emails/new"
          element={<PageWrapper titleKey="employee.signup.title" component={Signup} />}
        />
        <Route
          path="/agency_referrals"
          element={<PageWrapper titleKey="employee.referral.title" component={PublicAgencyReferralShow} />}
        />
      </Route>
    </Route>
  )
)

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Toaster />
    <RouterProvider router={router} />
  </QueryClientProvider>
)

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root')
  container?.classList.add('max-w-3xl', 'mx-auto')
  if (container) {
    i18next.init({
      lng: 'fr',
      resources: window.translation_keys,
    })
    initDayjs()

    const root = createRoot(container)
    root.render(<App />)
  }
})
